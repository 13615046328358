<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      v-loading="loading"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="staff-demo-ruleForm"
    >
      <el-form-item label="登录账号" prop="account">
        <el-input v-model="ruleForm.account" maxlength="24"></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="password1" v-if="ruleForm.id">
        <div class="reset-password">
          <el-input value="123456" type="password" disabled>
            <template slot="append">
              <i
                class="el-icon-view"
                style="cursor: pointer"
                @click="
                  passwordType == 'text'
                    ? (passwordType = 'password')
                    : (passwordType = 'text')
                "
              ></i>
            </template>
          </el-input>
          <!-- <el-button
            style="margin-left: 14px"
            type="primary"
            size="mini"
            disabled
            @click="ruleForm.password = generatePassword(8), passwordType = 'text'"
            >初始密码</el-button
          > -->
        </div>
      </el-form-item>
      <el-form-item label="登录密码" prop="password" v-else>
        <div class="reset-password">
          <el-input
            v-model.trim="ruleForm.password" placeholder="最小长度为8位，密码由数字、字母和特殊字符组合"
            :type="passwordType"
          >
            <template slot="append">
              <i
                class="el-icon-view"
                style="cursor: pointer"
                @click="
                  passwordType == 'text'
                    ? (passwordType = 'password')
                    : (passwordType = 'text')
                "
              ></i>
            </template>
          </el-input>
          <el-button
            style="margin-left: 14px"
            type="primary"
            size="mini"
            @click="ruleForm.password = 'Yili666+', passwordType = 'text'"
            >初始密码</el-button
          >
        </div>
      </el-form-item>
      <div
        style="
          border-bottom: 1px dashed #dedede;
          padding-top: 6px;
          margin-bottom: 24px;
        "
      ></div>
      <el-form-item
        label="租户名称"
        prop="tenantId"
        v-if="$store.state.tenantId === '000000'"
      >
        <el-select
          v-model="ruleForm.tenantId"
          remotepopper-class="staff-demo-ruleForm"
          placeholder="请选择租户"
          @change="handleTenantId"
          clearable
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="(item, ind) in tenantList"
            :key="ind"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="deptId">
        <el-select
          v-model="ruleForm.deptId"
          filterable
          remotepopper-class="staff-demo-ruleForm"
          placeholder="请选择所属部门"
          remote
          clearable
          @visible-change="visibleChange"
          :remote-method="remoteMethod"
        >
          <el-option label value>
            <div @click.stop="aa">
              <el-tree
                :data="deptOptions"
                check-strictly
                show-checkbox
                ref="deptTree"
                default-expand-all
                node-key="id"
                :props="defaultProps"
                @check-change="changeDeptId"
                :filter-node-method="filterNode"
              ></el-tree>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name" maxlength="32"></el-input>
      </el-form-item>
      <el-form-item label="员工编号" prop="code">
        <el-input v-model="ruleForm.code" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          maxlength="11"
          @input="handleNumLength($event, ruleForm, 'phone')"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="岗位" prop="postName">
        <el-input v-model="ruleForm.postName" maxlength="24"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select
          v-model="ruleForm.roleId"
          multiple
          filterable
          remote
          placeholder="请选择角色"
        >
          <el-option
            :label="item.roleName"
            :value="item.roleId"
            v-for="(item, index) in roleOptions"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="启用" prop="enable">
        <el-radio-group v-model="ruleForm.enable">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import {
  getOrganizationTree,
  getRoleList,
  submitUser,
  updateUser,
  getSystemRoleList,
} from "@/api/SystemData";
import { phoneFun, judgeEmail, judgePassword } from "@/utils/index";
import { validatePassword, generatePassword } from "@/utils/auth";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          if (!this.deptOptions || !this.deptOptions.length) {
            this.initList(
              this.ruleForm.tenantId,
              this.ruleForm.deptId.split(",")
            );
          } else {
            this.$nextTick(() => {
              this.$refs.deptTree.setCheckedKeys(
                this.ruleForm.deptId.split(",")
              );
              this.changeDeptId();
            });
          }
        }
        if (v && this.$store.state.tenantId === "000000") {
          this.deptOptions = [];
        } else if (!v) {
          if (this.$store.state.tenantId === "000000") {
            this.deptOptions = [];
          }
          this.ruleForm = {
            enable: 1,
            account: "",
            password: "",
            name: "",
            deptId: "",
            phone: "",
            roleId: "",
            code: "",
            email: "",
            nowUserId: "",
            postName: "",
            yiLiUserToken: "",
            tenantId: "",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入正确的密码"));
      } else if (!value) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    let validateEmail = (rule, value, callback) => {
      if (!judgeEmail(value) && value) {
        callback(new Error("请输入正确的邮箱"));
      } else {
        callback();
      }
    };
    let formValidatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!validatePassword(value) && value) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        enable: 1,
        account: "",
        password: "",
        name: "",
        deptId: "",
        phone: "",
        roleId: "",
        code: "",
        email: "",
        nowUserId: "",
        postName: "",
        yiLiUserToken: "",
        tenantId: "",
      },
      rules: {
        account: [
          { required: true, message: "请选择登录账号", trigger: "change" },
          {
            min: 0,
            max: 32,
            message: "长度不能超过24个字符",
            trigger: "change",
          },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "change" },
          {
            min: 0,
            max: 32,
            message: "长度不能超过32个字符",
            trigger: "change",
          },
        ],
        password: [
          { required: true, validator: formValidatePassword, trigger: "change" },
          { min: 8, max: 32, message: "密码最小长度为8位，密码由数字、字母和特殊字符组合", trigger: "change" },
        ],
        phone: [
          {
            required: true,
            validator: validateMobile,
            message: "请输入手机号",
            trigger: "change",
          },
        ],
        deptId: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
        tenantId: [
          { required: true, message: "请选择租户", trigger: "change" },
        ],
        // email: [
        //   {
        //     validator: validateEmail,
        //     message: "请输入正确的邮箱",
        //     trigger: "change",
        //   },
        // ],
      },
      loading: false,
      deptOptions: [],
      roleOptions: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      passwordType: "text",
      tenantList: [],
      generatePassword,
    };
  },
  async created() {
    if (this.$store.state.tenantId === "000000") {
      this.tenantList = JSON.parse(
        JSON.stringify(this.$store.state.tenantList)
      );
      this.tenantList.splice(0, 1);
    } else {
      this.initList(this.$store.state.tenantId);
    }
  },
  methods: {
    initList(tenantId, ids) {
      this.ruleForm.deptId = "";
      getOrganizationTree({ tenantId: tenantId }).then((res) => {
        this.deptOptions = res.data;
        if (ids) {
          this.$nextTick(() => {
            this.$refs.deptTree.setCheckedKeys(ids);
            this.changeDeptId();
          });
        }
      });
      this.ruleForm.roleId = this.editData.roleId;
      getSystemRoleList({ tenantId: tenantId }).then((res) => {
        this.roleOptions = res.data;
      });
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (!this.ruleForm.id) {
            let postData = {
              ...this.ruleForm,
              deptId: this.$refs.deptTree
                .getCheckedNodes()
                .map((val) => val.id)
                .toString(),
              roleId:
                this.ruleForm.roleId && this.ruleForm.roleId.length
                  ? this.ruleForm.roleId.toString()
                  : "",
              tenantId:
                this.$store.state.tenantId === "000000"
                  ? this.ruleForm.tenantId
                  : this.$store.state.tenantId,
              yiLiUserToken: this.$store.state.appToken,
              nowUserId: this.$store.state.userInfo.userId,
            };
            delete postData.deptName;
            delete postData.roleName;
            submitUser(postData)
              .then((res) => {
                this.handleClose();
                this.$emit("changeList");
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
              });
          } else {
            let postData = {
              ...this.ruleForm,
              deptId: this.$refs.deptTree
                .getCheckedNodes()
                .map((val) => val.id)
                .toString(),
              roleId:
                this.ruleForm.roleId && this.ruleForm.roleId.length
                  ? this.ruleForm.roleId.toString()
                  : "",
              tenantId:
                this.$store.state.tenantId === "000000"
                  ? this.ruleForm.tenantId
                  : this.$store.state.tenantId,
              yiLiUserToken: this.$store.state.appToken,
              nowUserId: this.$store.state.userInfo.userId,
            };
            delete postData.deptName;
            delete postData.roleName;
            delete postData.password;
            updateUser(postData)
              .then((res) => {
                this.loading = false;
                this.handleClose();
                this.$emit("changeList");
              })
              .catch((err) => {
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
        if (!this.deptOptions || !this.deptOptions.length) {
          this.initList(
            this.ruleForm.tenantId,
            this.ruleForm.deptId.split(",")
          );
        } else {
          this.$nextTick(() => {
            this.$refs.deptTree.setCheckedKeys(this.ruleForm.deptId.split(","));
            this.changeDeptId();
          });
        }
      } else {
        this.ruleForm = {
          enable: 1,
          account: "",
          password: "",
          name: "",
          deptId: "",
          phone: "",
          roleId: "",
          code: "",
          email: "",
          nowUserId: "",
          postName: "",
          yiLiUserToken: "",
          tenantId: "",
        };
      }
    },
    aa() {},
    changeDeptId() {
      this.ruleForm.deptId = this.$refs.deptTree
        .getCheckedNodes()
        .map((val) => val.title)
        .toString();
    },
    handleNumLength(e, tiem, key) {
      tiem[key] = e.replace(/[^\d]/g, "");
      if (e.length > 11) {
        const aa = e + "";
        tiem[key] = aa.slice(0, 11);
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    remoteMethod(e) {
      this.$refs.deptTree.filter(e);
    },
    visibleChange(v) {
      if (v) {
        this.$refs.deptTree.filter("");
      }
    },
    handleTenantId(val) {
      this.initList(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
.el-select-dropdown__item {
  height: auto;
  background-color: #fff !important;
}
.reset-password {
  display: flex;
}
</style>
