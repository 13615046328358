<template>
  <el-dialog
    title="员工导入"
    :visible.sync="dialogVisible"
    custom-class="policy-import-dialog"
    :close-on-click-modal="false"
    width="540px"
    :before-close="handleClose"
  >
    <div>
      <div class="policy-import-dialog-template">
        1.点击下载空的
        <span
          class="policy-import-dialog-template-span"
          @click="handleDownloadTemplate"
          >表格模板</span
        >
      </div>
      <div class="policy-import-dialog-template policy-import-dialog-b">
        <span>2.上传表格（支持格式：xls、xlsx）</span>
        <el-upload
          class="upload-demo"
          drag
          accept=".xls, .xlsx"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="handlePreview"
          :on-remove="handleRemove"
          multiple
          :limit="1"
          :auto-upload="false"
          :file-list="fileList"
        >
          <img
            src="@/assets/images/policy/upload.png"
            class="policy-import-dialog-b-img"
            alt
            srcset
          />
          <div class="policy-import-dialog-b-text">拖拽到此或点击上传表格</div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadTemplate, userImport } from "@/api/SystemData";
export default {
  props: {
    showDialog: Boolean,
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handlePreview(e) {
      this.fileList = [e];
    },
    handleRemove() {
      this.fileList = [];
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error("请选择文件");
        return false;
      }
      let formData = new FormData();
      formData.append("file", this.fileList[0].raw);
      userImport(formData)
        .then((res) => {
          this.$message.success("上传成功");
          this.dialogVisible = false;
          this.fileList = [];
          this.$emit("changeList");
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message.error("上传失败");
          }, 3000);
        });
      // this.dialogVisible = false;
    },
    handleDownloadTemplate() {
      downloadTemplate();
      // const link = document.createElement('a'); // 生成一个a标签。
      // link.href = '/resource/保单导入模板.xls';
      // link.click();
    },
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
}
</style>
