<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="
        (operateTitle = '添加'), (editData = {}), (isAddVisible = true)
      "
      @del-change="deleteItem"
      @export-data="exportData"
      @import-click="importUserData"
      tableName="StaffManagement"
    >
      <template slot="operate-left">
        <el-button
          type="primary"
          v-if="handleHasPerms('M22-staff-edit')"
          size="small"
          @click="handleForbidden(1)"
          >启用</el-button
        >
        <el-button
          type="warning"
          v-if="handleHasPerms('M22-staff-edit')"
          size="small"
          @click="handleForbidden(0)"
          >禁用</el-button
        >
      </template>
      <template slot="enable" slot-scope="{ item }">
        <el-switch
          v-model="item.enable"
          :active-value="1"
          :inactive-value="0"
          disabled
        ></el-switch>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          type="text"
          @click="handleResetPassword(item)"
          v-if="handleHasPerms('M22-staff-edit')"
          size="small"
          >重置密码</el-button
        >
        <el-button
          class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M22-staff-edit')"
          type="text"
          size="small"
          >编辑</el-button
        >
        <el-button
          class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M22-staff-edit')"
          type="text"
          size="small"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <staffAddDialog
      :dialogVisible.sync="isAddVisible"
      :title="operateTitle"
      :editData="editData"
      @changeList="changeList"
    ></staffAddDialog>
    <el-dialog
      title="重置密码"
      :visible.sync="isByUserVisible"
      :close-on-click-modal="false"
      width="400"
      :before-close="() => (isByUserVisible = false)"
    >
      <div class="reset-password">
        <div style="width: 120px">新密码</div>
        <el-input
          v-model.trim="editData.password"
          :type="passwordType" placeholder="最小长度为8位，密码由数字、字母和特殊字符组合"
        >
          <template slot="append">
            <i
              class="el-icon-view"
              style="cursor: pointer"
              @click="
                passwordType == 'text'
                  ? (passwordType = 'password')
                  : (passwordType = 'text')
              "
            ></i>
          </template>
        </el-input>
        <el-button
          style="margin-left: 14px"
          type="primary"
          size="mini"
          @click="editData.password = 'Yili666+', passwordType = 'text'"
          >初始密码</el-button
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isByUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateUser">确 定</el-button>
      </span>
    </el-dialog>
    <staff-import v-model="isImport" @changeList="changeList" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import staffAddDialog from "@/views/SystemManagement/components/staff/staffAddDialog.vue";
import staffImport from "@/views/SystemManagement/components/staff/staffImport.vue";
import {
  userList,
  deleteUser,
  getSystemRoleList,
  updateUser,
  exportUser,
  userEnable,
} from "@/api/SystemData";
import { hasPerms, validatePassword, generatePassword } from "@/utils/auth";
import { judgePassword } from "@/utils/index";
export default {
  data() {
    return {
      option: {
        isAdd: hasPerms("M22-staff-add"), //添加 事件 @add-change
        isDel: hasPerms("M22-staff-edit"), //删除 @del-change
        isEdit: hasPerms("M22-staff-edit"), // 编辑 @edit-change
        isExport: hasPerms("M22-staff-edit"),
        isImport: hasPerms("M22-staff-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: "--", // 列表数据为空时的显示
        searchList: [
          {
            label: "租户名称",
            prop: "tenantId",
            formType: "select",
            filterable: true,
            isPermissions: this.$store.state.tenantId === "000000",
            placeholder: "请选择租户",
            selectList: this.$store.state.tenantList,
          },
          {
            label: "登录账号",
            prop: "account",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入登录账号",
          },
          {
            label: "姓名",
            prop: "name",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入姓名",
          },
          {
            label: "电话",
            prop: "phone",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入电话",
          },
          {
            label: "岗位",
            prop: "postName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入岗位",
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入所属部门",
          },
          {
            label: "角色",
            prop: "roleId",
            formType: "select",
            filterable: true,
            placeholder: "请选择角色",
            selectList: [],
          },
          {
            label: "是否启用",
            prop: "enable",
            formType: "select",
            filterable: true,
            placeholder: "请选择类型",
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "是" },
              { value: "0", text: "否" },
            ],
          },
          {
            label: "邮箱",
            prop: "email",
            formType: "input",
            maxLength: 32,
            isPermissions: this.$store.state.tenantId === "000000",
            clearable: true,
            placeholder: "请输入邮箱",
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["createTimeBegin", "createTimeEnd"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "租户名称",
          prop: "tenantName",
          isPermissions: this.$store.state.tenantId === "000000",
          align: "center",
          isShow: true,
        },
        {
          label: "登录账号",
          prop: "account",
          align: "center",
          isShow: true,
        },
        {
          label: "姓名",
          prop: "name",
          align: "center",
          isShow: true,
        },
        {
          label: "电话",
          prop: "phone",
          align: "center",
          width: "100",
          isShow: true,
        },
        {
          label: "邮箱",
          prop: "email",
          align: "center",
          isShow: true,
        },
        {
          label: "岗位",
          prop: "postName",
          align: "center",
          isShow: true,
        },
        {
          label: "所属组织",
          prop: "deptName",
          align: "center",
          isShow: true,
        },
        {
          label: "员工编号",
          prop: "code",
          align: "center",
          isShow: true,
        },
        {
          label: "角色",
          prop: "roleName",
          align: "center",
          isShow: true,
        },
        {
          label: "启用状态",
          prop: "enable",
          align: "center",
          isShow: true,
          isSlot: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          align: "center",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: "center",
          width: 240,
          fixed: "right",
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: "添加",
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: "",
      passwordType: "text",
      isImport: false,
      generatePassword,
    };
  },
  components: { GeneralTable, staffAddDialog, staffImport },
  created() {
    getSystemRoleList({
      tenantId:
        this.$store.state.tenantId === "000000"
          ? ""
          : this.$store.state.tenantId,
    }).then((res) => {
      if (res.data && res.data.length) {
        let ind = this.option.searchList.findIndex(
          (val) => val.label === "角色"
        );
        this.option.searchList[ind].selectList = res.data.map((val) => {
          return {
            text: val.roleName,
            value: val.roleId,
          };
        });
        this.option.searchList[ind].selectList.unshift({
          text: "全部",
          value: "",
        });
      }
    });
  },
  watch: {},
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize) {
      const { code, data } = await userList({
        ...v,
        current: pageNum,
        size: pageSize,
        tenantId:
          this.$store.state.tenantId === "000000"
            ? v.tenantId
            : this.$store.state.tenantId,
        condition: v.keyword,
      });
      if (code === 200 && data.records && data.records.length) {
        this.claimRecordList = data.records.map((val) => {
          val.roleId ? (val.roleId = val.roleId.split(",")) : "";
          // val.deptId = val.deptId.split(',');
          return val;
        });
        this.totalNum = data.total;
      } else {
        this.claimRecordList = [];
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm("确认删除该用户?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let ids = e.map((val) => val.id).toString();
            deleteUser({
              ids,
              token: this.$store.state.appToken,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.changeList();
              })
              .catch((err) => {
                this.$message({
                  type: "error",
                  message: "失败!",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的角色！");
      }
    },
    // 编辑
    editItem(e) {
      e.password = "123456";
      this.editData = e;
      this.operateTitle = "编辑员工-" + e.name;
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path) {
      this.$router.push({
        path: path,
      });
    },
    handleResetPassword(e) {
      e.password = "";
      this.editData = e;
      this.operateTitle = "编辑员工-" + e.name;
      this.isByUserVisible = true;
    },
    handleUpdateUser() {
      if (
        !validatePassword(this.editData.password)
      ) {
        this.$message.error("请填写正确最小长度为8位，密码由数字、字母和特殊字符组合！");
        return false;
      }
      updateUser({
        ...this.editData,
        roleId:
          this.editData.roleId && this.editData.roleId.length
            ? this.editData.roleId.toString()
            : "",
        tenantId:
          this.$store.state.tenantId === "000000"
            ? this.editData.tenantId
            : this.$store.state.tenantId,
        yiLiUserToken: this.$store.state.appToken,
        nowUserId: this.$store.state.userInfo.userId,
      })
        .then((res) => {
          if (res) {
            this.isByUserVisible = false;
            this.$message.success("修改成功");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败！");
        });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的员工！');
      //   return false;
      // }
      exportUser({
        userIds: e.map((val) => val.id).toString(),
      });
    },
    importUserData() {
      this.isImport = true;
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleForbidden(e) {
      if (
        this.$refs.GeneralTable.tableSelectionList &&
        this.$refs.GeneralTable.tableSelectionList.length
      ) {
        let ids = this.$refs.GeneralTable.tableSelectionList
          .map((val) => val.id)
          .toString();
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        userEnable({
          ids,
          enable: e,
        })
          .then((res) => {
            loading.close();
            this.$message.success(res.msg || "操作成功");
            this.changeList();
          })
          .catch((err) => {
            loading.close();
            console.log(err);
          });
      } else {
        if (e === 1) {
          this.$message.warning("请选择需要启用的员工！");
        } else {
          this.$message.warning("请选择需要禁用的员工！");
        }
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
.reset-password {
  display: flex;
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>
